import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Summary.scss";
import { Select } from "../UI/Select/Select";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";
import { AnalyticsContext } from "../../context/analytics/analyticsContext";
import { SummaryItem } from "../Summary/SummaryItem/SummaryItem";
import { Loader } from "../UI/Loader/Loader";

export const Summary = (props) => {
  const {
    token,
    credentials,
    searchPossition,
    property,
    leadsSummary,
    analyticsRes,
    getCredentials,
    startSummary,
    setSearchPossition,
    setAnalyticsRes,
    setProperty,
  } = useContext(LoginHomeContext);
  const { cacheFullAnalytics } = useContext(AnalyticsContext);
  const [mount, setMount] = useState(false);
  // const [property, setProperty] = useState(0)
  const history = useHistory();

  useEffect(() => {
    async function start() {
      getCredentials(token).then((credentials) => {
        startSummary(credentials[property], property);
        return credentials;
      });
    }

    if (!mount) {
      setMount(true);
      start();
    }
  }, [
    property,
    getCredentials,
    startSummary,
    cacheFullAnalytics,
    token,
    props,
    mount,
  ]);

  const onClickButton = (link) => {
    history.push(link);
  };

  const multiplyProperty = credentials.length > 1;
  let properies = [];

  if (multiplyProperty) {
    properies = credentials.map((credential, index) => {
      return { value: index, text: credential["PROPERTY"] };
    });
  } else {
  }

  const selectChangeHandler = (value) => {
    setProperty(value);
    setSearchPossition(null);
    setAnalyticsRes(null);
    startSummary(credentials[value], value);
  };

  return (
    <div className="Summary">
      {multiplyProperty ? (
        <Select
          label="Select your property"
          onChange={selectChangeHandler}
          options={properies}
          selected={property}
        />
      ) : (
        ""
      )}

      {credentials[property] ? (
        <Auxiliary>
          <h2 className="invitation">Wellcome</h2>
          <img
            className="logo"
            src={credentials[property].LOGOURL}
            alt="Logo"
          />
        </Auxiliary>
      ) : (
        ""
      )}

      {!(searchPossition && leadsSummary[property] && analyticsRes) ? (
        <Loader />
      ) : (
        <div className="items-wrap">
          <SummaryItem
            title="Google search result"
            data={searchPossition}
            comment={`For key words:\n${credentials[property][
              "SEARCHWORDS"
            ].join(", ")}`}
            isButton={false}
          />

          <SummaryItem
            title="Leads"
            data={leadsSummary[property][0][0]}
            comment={`New ${
              leadsSummary[property][0][0] - leadsSummary[property][1][0]
            }`}
            isButton={true}
            buttonLabel="View details"
            onClickButton={onClickButton}
            buttonLink="leads"
          />

          <SummaryItem
            title="Visitors "
            data={analyticsRes}
            comment="For last 7 days"
            isButton={true}
            buttonLabel="View details"
            onClickButton={onClickButton}
            buttonLink="analytics"
          />
        </div>
      )}
    </div>
  );
};
