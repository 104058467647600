import React, {useContext} from 'react'
import {Navbar} from '../../components/Navbar/Navbar'
import {Footer} from '../../components/Footer/Footer'
import './Layout.scss'
import {LoginHomeContext} from '../../context/loginHome/loginHomeContext'
import {Auxiliary} from '../Auxilary/Auxiliary'
import {Alert} from '../../components/Alert/Alert'



export const Layout = (props) =>  {
  const {alertMessage} = useContext(LoginHomeContext)
    return (

        <div className="layout">     
          <Navbar/>


            <div className="main">
							<div className="container">
                <Auxiliary>

                        {props.children}
 
                    {alertMessage
                      ? <Alert alertMessage={alertMessage}/>
                      : '' } 
                </Auxiliary>
							</div>				
            </div>
          

          <Footer/>

        </div>

    )
}