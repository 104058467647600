import React, {useContext, useEffect, useState} from 'react'
import './Leads.scss'
import {LoginHomeContext} from '../../context/loginHome/loginHomeContext'
import {LeadsContext} from '../../context/leads/leadsContext'
import { Tabs } from '../../components/Tabs/Tabs';
import {Auxiliary} from '../../hoc/Auxilary/Auxiliary'
import {Select} from '../../components/UI/Select/Select'
import {LeadsDynamic} from '../../components/LeadsDinamic/LeadsDynamic'
import {Loader} from '../../components/UI/Loader/Loader'

export const Leads = (props) => {
  const {formData, getFormData} = useContext(LeadsContext)
  const {token, credentials, leadsSummary, property, setProperty, getLeads} = useContext(LoginHomeContext)
  const [mount, setMount] = useState(false)




  useEffect(() => {


    async function start() {
      if(token) {
        await getFormData(credentials[property], leadsSummary[property][0][1], property)      

      } else {
        props.history.push("/");
      }

    }
    if(!mount) {
      setMount(true);
      start()
    }
}, [mount, credentials, token, leadsSummary, getFormData, props, property])

const multiplyProperty = credentials.length > 1
let properies = []

if (multiplyProperty) {
  properies = credentials.map((credential, index) => {
    return ({'value' : index,
            'text' : credential['PROPERTY']})
  })
} else {

}

const selectChangeHandler = async (value) => {
  setProperty(value)
  if(formData[value] === undefined) {
    if(leadsSummary[value] !== undefined) {
      try {
        await getFormData(credentials[value], leadsSummary[property][0][1], value)  
      } catch (e) {
        console.log(e);
      }
    } else {
      getLeads(credentials[value]['WEBSITEURL'], credentials[value]['WEBSITEAPPPASSWORD'], value)
      .then((leadsSummary) => {
        console.log('leadsSummary inside Leads.js', leadsSummary)
        getFormData(credentials[value], leadsSummary[value][0][1], value)
        .then((formData) => {
          console.log('formData inside Leads.js', formData)
        })
  
      })
      .catch(console.log)
    }
  }

} 


  return (
    <div className="Leads">
				 {(formData[property]) ?
				<Auxiliary>
						{
					  (multiplyProperty)
            ? ( 
							<div className="Select-hidden">
								<span>Select property <span className="arrow"></span></span>
								<Select 
								label = ""
								onChange={selectChangeHandler}
								options = {properies} 
								selected = {property}
								/>
							</div>
						)
            : '' 
						}          
					<div className="leads-wrap leads-dynamic">
						<h2>Leads dynamic</h2>
              <LeadsDynamic/>

					</div>
					<div className="leads-wrap leads-forms">
						<h2>Web forms</h2>

						<Tabs/>
					</div>  	
				</Auxiliary> 
          : <Loader/>
          }
        
    </div>  

  )
}
